var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.html",
          modifiers: { hover: true, html: true },
        },
      ],
      staticClass: "admin-note",
      attrs: { title: _vm.value },
    },
    [
      _c("font-awesome-icon", {
        attrs: { icon: ["far", "comment"], size: "lg" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }