<template>
	<div class="admin-note" v-b-tooltip.hover.html :title="value">
		<font-awesome-icon :icon="['far', 'comment']" size="lg"></font-awesome-icon>
	</div>
</template>

<script>
export default {
	props: {
		value: { type: String, default: '' },
	},
};
</script>

<style lang="scss" scoped>
.admin-note {
	> svg:hover {
		color: var(--primary);
	}
}
</style>